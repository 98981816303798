<template>
  <div>
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          style="cursor: pointer"
          class="mx-5"
          @click="badgeCount = 0"
        >
          <v-badge
            offset-x="10"
            offset-y="10"
            color="green"
            :content="badgeCount"
            :value="badgeCount"
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </span>
      </template>
      <v-list two-line width="300">
        <v-subheader>{{ "Notificaciones: " + fechaDelDia }}</v-subheader>
        <v-virtual-scroll :items="items" :item-height="129" height="400">
          <template v-slot:default="{ item }">
            <v-divider></v-divider>

            <v-list-item :key="item.title">
              <v-list-item-avatar>
                <v-icon :class="item.type" dark size="28">{{
                  iconsData[item.type]
                }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  style="white-space: pre-line; font-size: 0.9rem"
                  >{{ item.titulo }}</v-list-item-title
                >
                <v-list-item-subtitle
                  style="white-space: pre-line; font-size: 0.8rem"
                  >{{ item.mensaje }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  style="white-space: pre-line; font-size: 0.7rem"
                  >{{ formatDate(item.createdAt) }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon
                    color="red"
                    @click="deleteNotificacion(item.id_notificacion)"
                    size="28"
                    >mdi-delete-forever</v-icon
                  >
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template></v-virtual-scroll
        >
      </v-list>
    </v-menu>
    <!-- <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            style="cursor: pointer"
            class="mx-5 mr-10"
          >
            <v-badge offset-x="10" offset-y="10" color="red" content="2">
              <v-icon>mdi-package-variant</v-icon>
            </v-badge>
          </span>
        </template>
        <v-list three-line width="250">
          <template>
            <v-subheader>{{ "Notificaciones: " + fechaDelDia }}</v-subheader>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Pedidos en Espera: 2</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu> -->
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import conexionSocket from "@/composables/conexionSocket.js";
export default {
  name: "NotificationBadgeDashboard.vue",
  data() {
    return {
      items: [],
      dataUsuario: "",
      iconsData: {
        warning: "mdi-alert",
        error: "mdi-close-box-multiple-outline",
        success: "mdi-check-bold",
        info: "mdi-information-variant",
      },

      badgeCount: 0,
    };
  },
  beforeCreate() {
    console.log("before");
    conexionSocket.on("notificacionUsuario", (data) => {
      console.log("data de notificacion", data);
      this.updateNotification(data);
    });
  },
  async created() {
    this.dataUsuario = JSON.parse(localStorage.getItem("token"));
    if (this.dataUsuario) {
      const notificaciones = await axios.get(
        `${process.env.VUE_APP_BASE_URL}notificaciones/usuario?id_rol=${this.dataUsuario.id_rol}`
      );
      this.items = notificaciones.data;
      this.badgeCount = this.items.length;
      console.log("response", this.items);
    } else {
      console.error("No se encontró 'dataUsuario' en el almacenamiento local.");
    }
  },

  computed: {
    fechaDelDia() {
      // Obtén la fecha del día usando Moment.js
      return moment().format("DD-MM-YY"); // Puedes ajustar el formato según tus necesidades
    },
  },
  methods: {
    deleteNotificacion(id) {
      console.log(this.items);
      const notificaciones = this.items;
      for (let i = 0; i < notificaciones.length; i++) {
        if (notificaciones[i].id_notificacion === id) {
          notificaciones.splice(i, 1);
          break;
        }
      }
      const eliminarNotificacion = axios
        .delete(`${process.env.VUE_APP_BASE_URL}notificaciones/usuario/${id}`)
        .then((res) => {
          console.log("eliminado", res);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateNotification(data) {
      this.items.push(data);
      this.badgeCount++;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
