<template>
  <v-app id="inspire" :style="{ backgroundColor: bgColor }">
    <Navigation v-if="loginState" />
    <!-- <Sidebar v-if="loginState"/> -->
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "./components/dashboard/Navigation.vue";
import Sidebar from "./components/Sidebar.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Navigation,
    Sidebar,
  },
  data: () => ({
    cards: ["Today", "Yesterday"],
    bgColor: "#f6f6f6",
  }),
  computed: {
    ...mapGetters(["loginState"]),
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      this.$store.dispatch("login", JSON.parse(token));
    }
    // }else{

    //   if (this.$router.currentRoute.name !== "Login")
    //   this.$router.push({ name: "Login" });
    // }
  },
  // watch: {
  //   // whenever question changes, this function will run
  //   drawer(newDrawer, old) {
  //     this.drawer=this.drawer
  //   }
  // }
};
</script>
