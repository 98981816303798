import router from "../../router";

const state = () => ({
  isLoggedIn: false,
  datosUsuario: {},
});
const getters = {
  loginState(state) {
    return state.isLoggedIn;
  },
  datosUser(state) {
    return state.datosUsuario;
  },
};
const mutations = {
  login(state, payload) {
    state.isLoggedIn = true;
    state.datosUsuario = payload;

    if (router.currentRoute.name == "Login")
      router.push("/").catch((err) => {
        console.log(err);
      });
  },
  logout(state) {
    state.isLoggedIn = false;
    localStorage.removeItem("token");
    localStorage.removeItem("id_caja");
    /* localStorage.removeItem("expires"); */
    router.push("/login");
  },
};
const actions = {
  login(context, payload) {
    // setTimeout(() => {
    //   context.commit("logout");
    // }, expires_in);
    /* console.log(expires_in) */
    context.commit("login", payload);
    //context.commit("guardarCarrito",payload)
  },
  logout(context) {
    context.commit("logout");
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
