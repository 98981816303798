import axios from "axios";

const state = () => ({
  carrito: JSON.parse(localStorage.getItem('carrito')) || [],
  activarModuloPago: false,
  mediosPagos: [],
  productosPOS: [],
  porcentaje: 0
});

const getters = {
  carritoState(state) {
    return state.carrito;
  },
  productosPOSState(state) {
    return state.productosPOS;
  },
  mediosPagosState(state) {
    return state.mediosPagos;
  },
  ModuloPagoState(state) {
    return state.activarModuloPago;
  },
  porcentajeDescuento(state) {
    return state.porcentaje;
  },
  totalPagadoState(state) {
    return state.mediosPagos.reduce((total, item) => {
      const pago = parseFloat(item.pago || 0);
      return total + pago;
    }, 0);
  },

  saldoRestanteState(state, getters) {
    const totalCarrito = getters.totalCarrito || 0;
    const totalPagado = getters.totalPagadoState || 0;
    return Number((totalCarrito - totalPagado).toFixed(2));
  },

  totalCarrito(state) {
    const subtotal = state.carrito.reduce((total, item) => {
      return total + (item.precio_unitario * item.unidades);
    }, 0);
    
    const descuento = subtotal * (state.porcentaje / 100);
    return Number((subtotal - descuento).toFixed(2));
  },
  subtotalCarrito(state) {
    return Number(state.carrito.reduce((total, item) => {
      return total + (item.precio_unitario * item.unidades);
    }, 0).toFixed(2));
  }
};
const mutations = {
  cargarMasivaCarrito(state, payload)
  {
    // Asegúrate de que el payload contenga una lista válida de productos
    if (Array.isArray(payload) && payload.length)
    {
      // Remplaza el contenido del carrito con los nuevos productos del payload
      state.carrito = payload.map(producto =>
      {
        // Asegurarse de que las unidades sean numéricas
        return {
          ...producto,
          unidades: Number(producto.unidades)
        };
      });

      // Actualizar el localStorage con el nuevo estado del carrito
      localStorage.setItem('carrito', JSON.stringify(state.carrito));

      // Verificar si el total del carrito alcanza el límite
      const total = getters.totalCarrito(state);
      if (total >= 170000)
      {
        state.activarModuloPago = true;
        console.log("El carrito ha alcanzado el límite de 170.000 pesos.");
      }
    } else
    {
      console.warn("El payload para cargar el carrito está vacío o no es válido.");
    }

  },
  activarPago(state, payload)
  {
    state.activarModuloPago = payload;
    console.log("desde la mutacion", state.activarModuloPago);
  },
  deleteIdCarrito(state, payload)
  {
    // Buscar el índice del producto en el carrito
    console.log("carrito", state.carrito);
    console.log("payload");

    const index = state.carrito.findIndex(
      (item) => item.id_producto === payload.id
    );
    console.log(index);

    if (index !== -1)
    {
      // Eliminar el producto del carrito
      state.carrito.splice(index, 1);
    }

    // Mostrar en consola el estado del carrito
    localStorage.setItem('carrito', JSON.stringify(state.carrito));
    console.log(
      "Producto eliminado. Estado actual del carrito:",
      state.carrito
    );
  },
  addCarrito(state, payload) {
    const campo = {
      ...payload,
      unidades: Number(payload.unidades),
    };

    const existingItemIndex = state.carrito.findIndex(
      (item) => item.id_producto === campo.id_producto
    );

    if (existingItemIndex !== -1) {
      const unidadesExistente = Number(state.carrito[existingItemIndex].unidades);
      state.carrito[existingItemIndex].unidades = Number(campo.unidades) + unidadesExistente;
    } else {
      state.carrito.unshift(campo);
    }

    // Verificar el total después de añadir el producto
    const total = getters.totalCarrito(state);
    if (total >= 170000) {
      state.activarModuloPago = true;
    }

    localStorage.setItem('carrito', JSON.stringify(state.carrito));
  },
  addPago(state, payload) {
    // Asegurarnos de que el pago tenga valores numéricos
    const pago = {
      ...payload,
      pago: Number(payload.pago || 0),
      montoIngresado: Number(payload.montoIngresado || 0)
    };
    state.mediosPagos.push(pago);
  },
  setPorcentajeDescuento(state, porcentaje)
  {
    state.porcentaje = Number(porcentaje);
  },

  finalizarVenta(state, payload)
  {
    state.carrito = [];
    state.activarModuloPago = false;
    state.mediosPagos = [];
    state.porcentaje = 0; // Resetear el porcentaje al finalizar
    localStorage.removeItem('carrito');
    localStorage.removeItem('datosFactura');
    localStorage.setItem('idUltimoPedido', payload);
  },
  eliminarMediosPagosState(state)
  {
    state.mediosPagos = [];
  },
  getProductosLoadPOS(state, payload)
  {
    state.productosPOS = payload;
    // state.loderProducts = false;
  }

};
const actions = {
  aplicarDescuento({ commit }, porcentaje)
  {
    commit('setPorcentajeDescuento', porcentaje);
  },

  handleCarrito({ commit, getters, state }, producto) {
    return new Promise((resolve, reject) => {
      // Calculamos el total actual
      const carritoActual = state.carrito;
      const totalActual = carritoActual.reduce((total, item) => {
        return total + (item.precio_unitario * item.unidades);
      }, 0);

      // Calculamos cuánto agregaría el nuevo producto
      const productoTotal = producto.precio_unitario * producto.unidades;
      
      if (producto.eliminar) {
        commit("deleteIdCarrito", producto);
        resolve();
      } else if (totalActual + productoTotal > 170000) {
        reject("No se puede agregar este producto. El carrito superaría el límite de 170.000 pesos.");
      } else {
        commit("addCarrito", producto);
        resolve();
      }
    });
  },
  handleCarritoID({ commit }, payload)
  {
    return new Promise(async (resolve, reject) =>
    {
      try
      {
        let r = await axios.get(
          process.env.VUE_APP_BASE_URL + "pedidos-pos/" + payload
        );
        console.log(r.data.response);
        commit("cargarMasivaCarrito", r.data.response.detalle_pedidos)
        resolve()
      } catch (e)
      {
        console.log(e);
        reject("No se encontro la compra")
      }

    })
  },
  handlePago({ commit }, payload)
  {
    commit("activarPago", payload);
  },
  eliminarMediosPagos({ commit })
  {
    commit("eliminarMediosPagosState");
  },
  handleMedioPago({ commit }, payload)
  {
    commit("addPago", payload);
  },
  handleFinalizarVenta({ commit }, payload)
  {
    commit("finalizarVenta", payload);
  },
  getProductosPOS({ commit, state }, refresh)
  {
    return new Promise(async (resolve) =>
    {
      try
      {

        if (state.productosPOS.length !== 0 && !refresh)
        {
          console.log("entro en el return de productos");
          resolve();
          return;
        }
        state.productosPOS = [];
        // state.loderProducts = true;
        console.log('>>>>>', state.productosPOS);
        let resp = await axios.get(process.env.VUE_APP_BASE_URL + "products-pos");
        console.log("entro en el get productos pos", resp);
        commit("getProductosLoadPOS", resp.data);
        resolve();
      } catch (e)
      {
        console.log("erroorrrr", e);
      }
    });
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
